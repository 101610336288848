import { useQuery } from "@tanstack/react-query";
import { isEqual } from "lodash";
import { useState, useEffect, useMemo, useCallback, useRef } from "react";

import LoadingAnimation from "src/components/Animations/LoadingAnimation";
import InputError from "src/components/Errors/InputError";
import SquareCheckboxInput from "src/components/Inputs/SquareCheckboxInput";
import DescriptionText from "src/components/Text/DescriptionText";

export type Department = NestedItem<Role> & {
  id: number;
  name: string;
};

export type Role = {
  id: number;
  name: string;
  selected: boolean;
};

export interface NestedItem<
  SubItemType extends { id: number; selected: boolean }
> {
  id: number;
  selected: boolean;
  subItems: SubItemType[];
  [key: string]: any;
}

type NestedCheckBoxInputProps<
  ItemType extends NestedItem<SubItemType>,
  SubItemType extends { id: number; selected: boolean }
> = {
  error?: string;
  register: any;
  setValue: any;
  queryFn: () => Promise<{ data: ItemType[] }>;
  inputName: string;
  itemName: keyof ItemType & string;
  subItemName: keyof SubItemType & string;
  subItemKey: keyof ItemType & string;
  defaultSelectedSubIds?: number[];
};

function NestedCheckBoxesInput<
  ItemType extends NestedItem<SubItemType>,
  SubItemType extends { id: number; selected: boolean }
>({
  error,
  register,
  setValue,
  queryFn,
  inputName,
  subItemKey,
  itemName,
  subItemName,
  defaultSelectedSubIds = [],
}: NestedCheckBoxInputProps<ItemType, SubItemType>) {
  const [items, setItems] = useState<ItemType[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  const { data, isLoading } = useQuery({
    queryKey: [queryFn],
    queryFn: () => queryFn().then((res) => res.data),
  });

  const toggleSelection = useCallback(
    (item: ItemType, selected: boolean) => ({
      ...item,
      selected: selected,
      [subItemKey]: item[subItemKey].map((subItem: SubItemType) => ({
        ...subItem,
        selected: selected,
      })),
    }),
    [subItemKey]
  );

  const initialRender = useRef(true);

  useEffect(() => {
    if (data && initialRender.current) {
      const updatedItems = data.map((item) => {
        // Check if any subitem should be selected by default
        const updatedSubItems = item[subItemKey].map((subItem: SubItemType) => {
          return {
            ...subItem,
            selected: defaultSelectedSubIds.includes(subItem.id),
          };
        });

        return {
          ...item,
          selected: updatedSubItems.every((sub: SubItemType) => sub.selected),
          [subItemKey]: updatedSubItems,
        };
      });

      if (!isEqual(updatedItems, items)) {
        setItems(updatedItems);
      }

      initialRender.current = false; // Set the flag to false after the initial run
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, subItemKey, defaultSelectedSubIds]);

  const handleSelectAllChange = useCallback(
    (selected: boolean) => {
      setSelectAll(selected);
      setItems((prevItems) =>
        prevItems.map((item) => toggleSelection(item, selected))
      );
    },
    [toggleSelection]
  );

  const handleItemChange = useCallback(
    (item: ItemType, isSelected: boolean) => {
      if (item.selected !== isSelected) {
        setItems((prevItems) =>
          prevItems.map((itm) =>
            itm.id === item.id ? toggleSelection(itm, isSelected) : itm
          )
        );
      }
    },
    [toggleSelection]
  );

  const handleSubItemChange = useCallback(
    (itemId: number, subItemId: number, isSelected: boolean) => {
      setItems((prevItems) =>
        prevItems.map((item) => {
          if (item.id === itemId) {
            const updatedSubItems = item[subItemKey].map(
              (subItem: SubItemType) =>
                subItem.id === subItemId
                  ? { ...subItem, selected: isSelected }
                  : subItem
            );
            const allSubItemsSelected = updatedSubItems.every(
              (subItem: SubItemType) => subItem.selected
            );
            return {
              ...item,
              selected: allSubItemsSelected,
              [subItemKey]: updatedSubItems,
            };
          } else {
            return item;
          }
        })
      );
    },
    [subItemKey]
  );

  const selectedSubItemIds = useMemo(() => {
    return items
      .flatMap((item) => item[subItemKey])
      .filter((subItem) => subItem.selected)
      .map((subItem) => subItem.id);
  }, [items, subItemKey]);

  useEffect(() => {
    register(inputName);
    setValue(inputName, selectedSubItemIds);
  }, [register, setValue, selectedSubItemIds, inputName]);

  useEffect(() => {
    const allSelected = items.every((item) =>
      item[subItemKey].every((subItem: SubItemType) => subItem.selected)
    );

    setSelectAll(allSelected);
  }, [items, subItemKey]);

  return (
    <div>
      {isLoading ? (
        <>
          <LoadingAnimation>
            <DescriptionText>Loading departments...</DescriptionText>
          </LoadingAnimation>
        </>
      ) : null}
      {items && !isLoading ? (
        <SquareCheckboxInput
          id="selectAll"
          name="selectAll"
          label={"Select All"}
          checked={selectAll}
          onChange={(e) => handleSelectAllChange(e.target.checked)}
        />
      ) : null}
      {items.map((item) => (
        <div key={item.id}>
          <div style={{ marginLeft: "2rem" }}>
            <SquareCheckboxInput
              id={`item-${item.id}`}
              key={item.id}
              name={String(item.id)}
              label={item[itemName] as string}
              checked={item.selected}
              onChange={(e) => handleItemChange(item, e.target.checked)}
            />
            <div style={{ marginLeft: "2rem" }}>
              {item[subItemKey].map((subItem: SubItemType) => (
                <SquareCheckboxInput
                  id={`subItem-${subItem.id}`}
                  key={subItem.id}
                  name={String(subItem.id)}
                  label={subItem[subItemName] as string}
                  checked={subItem.selected}
                  onChange={(e) =>
                    handleSubItemChange(item.id, subItem.id, e.target.checked)
                  }
                />
              ))}
            </div>
          </div>
        </div>
      ))}
      {error ? <InputError error={error} /> : null}
    </div>
  );
}

export default NestedCheckBoxesInput;
