import { lazy } from "react";
import {
  LOGIN,
  CREATE_A_VIDEO,
  REQUEST_USERNAME,
  RESEND_INVITE,
} from "src/features/buttons/titles";
import {
  ADD_DROPDOWN_LIST,
  ADD_CHECKBOX_OPTIONS,
  INVITE_EMPLOYEE,
  INVITE_SUPERVISOR,
  CREATE_A_CATEGORY,
  CREATE_A_DISCUSSION,
  DELETE_EMPLOYEE_LEARNING_PATH,
  DELETE_COLUMN,
  DELETE_INVITE,
  DELETE_MEMO,
  DELETE_VIDEO,
  DELETE_POLICY,
  DELETE_LEARNING_PATH,
  DELETE_ASSESSMENT,
  DELETE_SCHEDULE,
  DELETE_TEMPLATE,
  DELETE_DOCUMENT,
  DELETE_TODO,
  SCHEDULE_FORM,
  EDIT_VIDEO,
  EMPLOYEE_REGISTRATION,
  IMPORT_EXISTING_QUESTION,
  LEARNING_PATH_DETAILS,
  LEARNING_PATH,
  LINK_A_VIDEO,
  PASSWORD_RESET,
  PASSWORD_RESET_REQUEST,
  SEND_REMINDER_NOTIFICATIONS,
  UPDATE_EMPLOYEE_DETAILS,
  VERIFY_PHONE_NUMBER,
  VIDEO_PREVIEW,
  UPLOAD_FILE_TO_DISCUSSION,
  POLICY_READ_STATUS,
  CREATE_DOCUMENT,
  SELECT_EMPLOYEE,
  SEND_DOCUMENT,
  REISSUE_ASSESSMENT,
  REISSUE_LP_TO_EMPLOYEE,
  CREATE_A_POLICY_CATEGORY,
  LINK_A_POLICY,
  LINK_AN_ASSESSMENT,
  DELETE_NOTE,
} from "src/features/modal/titles";
import lazyRetry from "src/features/utils/lazyRetry";

const DeleteNoteForm = lazy(() =>
  lazyRetry(() => import("src/features/notes/components/DeleteNoteForm"))
);
const ColumnDeleteForm = lazy(() =>
  lazyRetry(() => import("src/features/notes/components/DeleteColumnForm"))
);
const LinkAssessmentForm = lazy(() =>
  lazyRetry(
    () => import("src/features/discussions/components/LinkAssessmentForm")
  )
);

const LinkPolicyForm = lazy(() =>
  lazyRetry(() => import("src/features/discussions/components/LinkPolicyForm"))
);
const PoliciesCategoriesForm = lazy(() =>
  lazyRetry(
    () => import("src/features/policies/components/PoliciesCategoriesForm")
  )
);
const ScheduleForm = lazy(() =>
  lazyRetry(() => import("src/features/todos/components/ScheduleForm"))
);
const DeleteScheduleForm = lazy(() =>
  lazyRetry(() => import("src/features/todos/components/DeleteScheduleForm"))
);
const ReissueLpToEmployeeForm = lazy(() =>
  lazyRetry(
    () =>
      import(
        "src/features/learningPaths/components/ModalForms/ReissueLpToEmployeeForm"
      )
  )
);
const ReissueAssessmentForm = lazy(() =>
  lazyRetry(
    () =>
      import(
        "src/features/assessments/components/ModalForms/ReissueAssessmentForm"
      )
  )
);
const ImportQuestionForm = lazy(() =>
  lazyRetry(
    () =>
      import(
        "src/features/assessments/components/AssessmentForm/ImportQuestionForm"
      )
  )
);
const SendDocumentModalForm = lazy(() =>
  lazyRetry(
    () =>
      import(
        "src/features/e-signatures/components/Documents/SendDocumentModalForm"
      )
  )
);

const DocumentAnnotationSelectEmployee = lazy(() =>
  lazyRetry(
    () =>
      import(
        "src/features/e-signatures/components/Documents/DocumentAnnotationSelectEmployee"
      )
  )
);
const DocumentDeleteForm = lazy(() =>
  lazyRetry(
    () =>
      import(
        "src/features/e-signatures/components/Documents/DocumentDeleteForm"
      )
  )
);
const TemplateDeleteForm = lazy(() =>
  lazyRetry(
    () =>
      import(
        "src/features/e-signatures/components/TemplateComponents/TemplateDeleteForm"
      )
  )
);
const CreateDocumentModalForm = lazy(() =>
  lazyRetry(
    () =>
      import(
        "src/features/e-signatures/components/Documents/CreateDocumentModalForm"
      )
  )
);

const TodoDeleteForm = lazy(() =>
  lazyRetry(() => import("src/features/todos/components/TodoDeleteForm"))
);
const AssessmentDeleteForm = lazy(() =>
  lazyRetry(
    () => import("src/features/assessments/components/AssessmentDeleteForm")
  )
);
const ForgotUsernameForm = lazy(() =>
  lazyRetry(() => import("src/features/auth/components/RequestUsernameForm"))
);
const LoginForm = lazy(() =>
  lazyRetry(() => import("src/features/auth/components/LoginForm"))
);
const ResetPasswordForm = lazy(() =>
  lazyRetry(() => import("src/features/auth/components/ResetPasswordForm"))
);
const RequestPasswordResetForm = lazy(() =>
  lazyRetry(
    () => import("src/features/auth/components/RequestPasswordResetForm")
  )
);
const CreateCategoryForm = lazy(() =>
  lazyRetry(
    () => import("src/features/categories/components/CreateCategoryForm")
  )
);
const DiscussionNotificationsForm = lazy(() =>
  lazyRetry(
    () =>
      import("src/features/discussions/components/DiscussionNotificationsForm")
  )
);
const LinkVideoForm = lazy(() =>
  lazyRetry(() => import("src/features/discussions/components/LinkVideoForm"))
);
const DiscussionsForm = lazy(() =>
  lazyRetry(() => import("src/features/discussions/components/DiscussionsForm"))
);
const EmployeeDetailForm = lazy(() =>
  lazyRetry(
    () => import("src/features/employees/components/EmployeeDetailForm")
  )
);
const RegisterEmployeeForm = lazy(() =>
  lazyRetry(() => import("src/features/auth/components/RegisterEmployeeForm"))
);
const DeleteEmployeeLPForm = lazy(() =>
  lazyRetry(
    () =>
      import(
        "src/features/learningPaths/components/ModalForms/DeleteEmployeeLPForm"
      )
  )
);
const EmployeeLPDetailsModalWindow = lazy(() =>
  lazyRetry(
    () =>
      import(
        "src/features/learningPaths/components/LPReports/EmployeeLPDetailsModalWindow"
      )
  )
);
const ContinueLearningPathForm = lazy(() =>
  lazyRetry(
    () =>
      import(
        "src/features/learningPaths/components/MyLearningPaths/ContinueLearningPathForm"
      )
  )
);
const LPDeleteForm = lazy(() =>
  lazyRetry(
    () =>
      import("src/features/learningPaths/components/ModalForms/LPDeleteForm")
  )
);
const MemoDeleteForm = lazy(() =>
  lazyRetry(() => import("src/features/memos/components/MemoDeleteForm"))
);
const PhoneVerificationForm = lazy(() =>
  lazyRetry(() => import("src/features/phone/components/PhoneVerificationForm"))
);
const PolicyReadTable = lazy(() =>
  lazyRetry(() => import("src/features/policies/components/PolicyReadTable"))
);
const VideoDeleteForm = lazy(() =>
  lazyRetry(() => import("src/features/videos/components/VideoDeleteForm"))
);
const VideoForm = lazy(() =>
  lazyRetry(() => import("src/features/videos/components/VideoForm"))
);
const VideoPreviewModal = lazy(() =>
  lazyRetry(() => import("src/features/videos/components/VideoPreviewModal"))
);
const ResendInviteForm = lazy(() =>
  lazyRetry(() => import("src/features/invites/components/ResendInviteForm"))
);
const InviteDeleteForm = lazy(() =>
  lazyRetry(() => import("src/features/invites/components/InviteDeleteForm"))
);
const InviteFormV2 = lazy(() =>
  lazyRetry(() => import("src/features/invites/components/InviteFormV2"))
);
const DiscussionUploadFileFormV2 = lazy(() =>
  lazyRetry(
    () =>
      import("src/features/discussions/components/DiscussionUploadFileFormV2")
  )
);
const PolicyDeleteForm = lazy(() =>
  lazyRetry(() => import("src/features/policies/components/PolicyDeleteForm"))
);

const TemplateAnnotationOptionsForm = lazy(() =>
  lazyRetry(
    () =>
      import(
        "src/features/e-signatures/components/Annotations/TemplateAnnotationOptionsForm"
      )
  )
);

const FORM_COMPONENT_MAP: { [key: string]: any } = {
  [ADD_DROPDOWN_LIST]: TemplateAnnotationOptionsForm,
  [ADD_CHECKBOX_OPTIONS]: TemplateAnnotationOptionsForm,
  [LOGIN]: LoginForm,
  [CREATE_A_CATEGORY]: CreateCategoryForm,
  [CREATE_A_DISCUSSION]: DiscussionsForm,
  [CREATE_A_VIDEO]: VideoForm,
  [DELETE_ASSESSMENT]: AssessmentDeleteForm,
  [CREATE_A_POLICY_CATEGORY]: PoliciesCategoriesForm,
  [CREATE_DOCUMENT]: CreateDocumentModalForm,
  [DELETE_COLUMN]: ColumnDeleteForm,
  [DELETE_DOCUMENT]: DocumentDeleteForm,
  [DELETE_EMPLOYEE_LEARNING_PATH]: DeleteEmployeeLPForm,
  [DELETE_INVITE]: InviteDeleteForm,
  [DELETE_LEARNING_PATH]: LPDeleteForm,
  [DELETE_MEMO]: MemoDeleteForm,
  [DELETE_NOTE]: DeleteNoteForm,
  [DELETE_POLICY]: PolicyDeleteForm,
  [DELETE_SCHEDULE]: DeleteScheduleForm,
  [DELETE_TEMPLATE]: TemplateDeleteForm,
  [DELETE_TODO]: TodoDeleteForm,
  [DELETE_VIDEO]: VideoDeleteForm,
  [EDIT_VIDEO]: VideoForm,
  [IMPORT_EXISTING_QUESTION]: ImportQuestionForm,
  [INVITE_EMPLOYEE]: InviteFormV2,
  [INVITE_SUPERVISOR]: InviteFormV2,
  [LEARNING_PATH_DETAILS]: EmployeeLPDetailsModalWindow,
  [EMPLOYEE_REGISTRATION]: RegisterEmployeeForm,
  [LEARNING_PATH]: ContinueLearningPathForm,
  [PASSWORD_RESET]: ResetPasswordForm,
  [PASSWORD_RESET_REQUEST]: RequestPasswordResetForm,
  [POLICY_READ_STATUS]: PolicyReadTable,
  [REISSUE_ASSESSMENT]: ReissueAssessmentForm,
  [REISSUE_LP_TO_EMPLOYEE]: ReissueLpToEmployeeForm,
  [REQUEST_USERNAME]: ForgotUsernameForm,
  [RESEND_INVITE]: ResendInviteForm,
  [SCHEDULE_FORM]: ScheduleForm,
  [SEND_DOCUMENT]: SendDocumentModalForm,
  [SELECT_EMPLOYEE]: DocumentAnnotationSelectEmployee,
  [SEND_REMINDER_NOTIFICATIONS]: DiscussionNotificationsForm,
  [LINK_A_POLICY]: LinkPolicyForm,
  [LINK_A_VIDEO]: LinkVideoForm,
  [LINK_AN_ASSESSMENT]: LinkAssessmentForm,
  [UPDATE_EMPLOYEE_DETAILS]: EmployeeDetailForm,
  [UPLOAD_FILE_TO_DISCUSSION]: DiscussionUploadFileFormV2,
  [VERIFY_PHONE_NUMBER]: PhoneVerificationForm,
  [VIDEO_PREVIEW]: VideoPreviewModal,
};

function getFormMapComponent(title: string) {
  const Component = FORM_COMPONENT_MAP[title];
  return Component ? <Component /> : null;
}

export default getFormMapComponent;
