import React, { forwardRef, memo } from "react";

interface TabProps {
  title: React.ReactNode;
  isActive?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  isVisible?: boolean;
}

const Tab = forwardRef<HTMLDivElement, TabProps>(
  ({ title, isActive, onClick, children, style, className }, ref) => {
    return (
      <div
        ref={ref}
        className={`${"tab"} ${isActive ? "active" : ""} ${className || ""}`}
        style={style}
        onClick={onClick}
      >
        {title}
      </div>
    );
  }
);

export default memo(Tab);
