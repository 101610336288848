import { memo, useMemo } from "react";
import styles from "./PathList.module.css";
import CheckIconSmall from "src/components/Icons/CheckIconSmall";
import VerticalStepper from "src/components/Steps/VerticalStepper";
import YellowCircle24Icon from "src/components/Icons/YellowCircle24Icon";
import useGetEmployeeLearningPathQuery from "../../hooks/useGetEmployeeLearningPathQuery";
import useGetLearningPathDetailQuery from "../../hooks/useGetLearningPathDetailQuery";
import EmployeeModuleContentsStatusList from "./EmployeeModuleContentsStatusList";
import Skeleton from "react-loading-skeleton";

type PathListProps = {
  employeeLearningPathId?: string;
};

function EmployeeLearningPathStatusList({
  employeeLearningPathId,
}: PathListProps) {
  const {
    data: employeeLearningPath,
    isLoading: isEmployeeLearningPathLoading,
  } = useGetEmployeeLearningPathQuery({
    id: employeeLearningPathId ? String(employeeLearningPathId) : undefined,
  });

  const { data: learningPath, isLoading: isLearningPathLoading } =
    useGetLearningPathDetailQuery({
      id: employeeLearningPath?.learning_path
        ? String(employeeLearningPath?.learning_path)
        : undefined,
    });

  const isAnyLoading = isEmployeeLearningPathLoading || isLearningPathLoading;

  const steps = useMemo(() => {
    if (isAnyLoading) {
      return Array.from({ length: 3 }).map((_, index) => ({
        icon: <Skeleton key={`icon-${index}`} />,
        title: <Skeleton key={`title-${index}`} />,
        content: <Skeleton key={`content-${index}`} />,
      }));
    }
    if (!employeeLearningPath || !learningPath) return [];

    if (!employeeLearningPath.employee_modules) return [];
    return employeeLearningPath.employee_modules.map((employeeModule) => {
      const module = learningPath.modules.find(
        (m) => m.id === employeeModule.module
      );
      const moduleTitle = module ? module.title : "Module Not Found";

      return {
        icon:
          employeeModule.status === 2 ? (
            <CheckIconSmall />
          ) : (
            <YellowCircle24Icon />
          ),
        title: <h4 className={styles.moduleTitle}>{moduleTitle}</h4>,
        content: employeeModule ? (
          <EmployeeModuleContentsStatusList
            employeeModuleId={String(employeeModule?.id)}
            key={employeeModule?.id}
          />
        ) : null,
      };
    });
  }, [employeeLearningPath, learningPath, isAnyLoading]);
  return (
    <div className={styles.pathListContainer}>
      <VerticalStepper steps={steps} />
    </div>
  );
}

export default memo(EmployeeLearningPathStatusList);
