import { useQuery } from "@tanstack/react-query";
import employeeLearningPathsService from "../services/employeeLearningPathsService";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

type Props = {
  id?: number | string;
};

export default function useGetEmployeeLearningPathQuery({ id }: Props) {
  return useQuery({
    queryKey: [QueryKeys.EmployeeLearningPath, String(id)],
    queryFn: id
      ? () => employeeLearningPathsService.getEmployeeLearningPath(id)
      : undefined,
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
}
