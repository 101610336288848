import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import Card from "src/components/Cards/Card";
import Container from "src/components/Container/Container";
import ExpandableListBox from "src/components/ListBox/ExpandableListBox";
import EmployeeLearningPathStatusList from "./EmployeeLearningPathStatusList";
import useGetEmployeeLearningPathQuery from "../../hooks/useGetEmployeeLearningPathQuery";
import useGetLearningPathDetailQuery from "../../hooks/useGetLearningPathDetailQuery";

export default function MyLearningPathDetailsListCard() {
  const { id } = useParams();
  const {
    data: employeeLearningPath,
    isLoading: isEmployeeLearningPathLoading,
  } = useGetEmployeeLearningPathQuery({
    id: id ? String(id) : undefined,
  });

  const { data: learningPath, isLoading: isLearningPathLoading } =
    useGetLearningPathDetailQuery({
      id: employeeLearningPath?.learning_path
        ? String(employeeLearningPath?.learning_path)
        : undefined,
    });

  const isAnyLoading = isEmployeeLearningPathLoading || isLearningPathLoading;
  return (
    <Card style={{ flex: 1 }}>
      <Container style={{ padding: "10px", gap: "5px" }}>
        {!isAnyLoading && employeeLearningPath && learningPath ? (
          <ExpandableListBox
            key={employeeLearningPath.id}
            title={learningPath?.title}
            subtitle={
              employeeLearningPath.completion_percentage + "% completed"
            }
            listContent={
              <EmployeeLearningPathStatusList employeeLearningPathId={id} />
            }
            href={``}
            isSelected={null}
            isLinkDisabled={true}
            defaultExpanded={true}
          />
        ) : null}

        {isAnyLoading ? <Skeleton height={300} /> : null}
      </Container>
    </Card>
  );
}
