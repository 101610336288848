import {
  PATH_LINK_ASSESSMENT,
  PATH_SKIP_CHOICES,
  ROUTE_API_MODULES,
  ROUTE_API_MODULE_CONTENTS,
} from "src/features/site/routes";
import axiosClient from "../../axios/client";
import { LearningPath, ModuleContentType } from "../learningPaths.types";
import { CreateModuleContentFromVideoVariables } from "../hooks/useLPAddVideoMutation";
import { UpdateModuleContentVideoVariables } from "../hooks/useUpdateMCVideoMutation";
import { InfiniteQueryFilters } from "src/components/Lists/FilterableListSidebar";
import { UploadModuleContentFileFormSchemaType } from "../zod/uploadModuleContentFileSchema";

type UpdateModuleTitle = {
  id: string;
  title: string;
};

type LinkAssessmentToModuleContentRequest = {
  assessmentId: number | string;
  moduleId: number | string;
};

class LearningPathService {
  async createEmptyModule(id: string): Promise<LearningPath> {
    return await axiosClient
      .post(`/api/learning-paths/${id}/create-empty-module/`)
      .then((res) => res.data);
  }

  async createModuleContentFile(data: UploadModuleContentFileFormSchemaType) {
    return await axiosClient
      .post(`/api/module-contents/add-file/`, data)
      .then((res) => res.data);
  }

  async createModuleContentFromPolicy({
    module_id,
    policy_id,
    start_page,
    end_page,
    can_employee_skip = 0,
  }: any): Promise<ModuleContentType> {
    return await axiosClient
      .post("/api/module-contents/add-policy/", {
        module_id,
        policy_id,
        start_page,
        end_page,
        can_employee_skip,
      })
      .then((res) => res.data);
  }

  async createModuleContentFromVideo({
    module_id,
    video_id,
    can_employee_skip = 0,
  }: CreateModuleContentFromVideoVariables): Promise<ModuleContentType> {
    return await axiosClient
      .post(`/api/module-contents/add-video/`, {
        module_id,
        video_id,
        can_employee_skip,
      })
      .then((res) => res.data);
  }

  async updateModuleContentPolicy({
    module_content_id,
    policy_id,
    start_page,
    end_page,
    can_employee_skip,
  }: any): Promise<ModuleContentType> {
    return await axiosClient
      .patch(`/api/module-contents/${module_content_id}/update-policy/`, {
        policy_id,
        start_page,
        end_page,
        can_employee_skip,
      })
      .then((res) => res.data);
  }

  async updateModuleContentVideo({
    module_content_id,
    video_id,
    can_employee_skip,
  }: UpdateModuleContentVideoVariables): Promise<ModuleContentType> {
    return await axiosClient
      .patch(
        `${ROUTE_API_MODULE_CONTENTS}/${module_content_id}/update-video/`,
        {
          video_id,
          can_employee_skip,
        }
      )
      .then((res) => res.data);
  }

  async createLearningPath(data: any): Promise<LearningPath> {
    return await axiosClient
      .post(`/api/learning-paths/`, data)
      .then((res) => res.data);
  }

  async deleteLearningPath(id: number) {
    return await axiosClient
      .delete(`/api/learning-paths/${id}/`)
      .then((res) => res.data);
  }

  async deleteModule(id: string) {
    return await axiosClient
      .delete(`${ROUTE_API_MODULES}/${id}/`)
      .then((res) => res.data);
  }

  async findAndTakeLearningPath({ id }: { id: string }) {
    return await axiosClient
      .post(`/api/learning-paths/${id}/find-and-take/`)
      .then((res) => res.data);
  }

  async getLearningPath(id: string): Promise<LearningPath> {
    return await axiosClient
      .get(`/api/learning-paths/${id}/`)
      .then((res) => res.data);
  }

  async getLearningPaths({
    pageParam,
    startDate,
    endDate,
    ...filters
  }: InfiniteQueryFilters) {
    return await axiosClient.get("/api/learning-paths/", {
      params: {
        page: pageParam,
        start_date: startDate
          ? new Date(startDate).toISOString().split("T")[0]
          : undefined,
        end_date: endDate
          ? new Date(endDate).toISOString().split("T")[0]
          : undefined,
        ...filters,
      },
    });
  }

  async getLearningPathAverageTimes(id?: string | number) {
    return await axiosClient
      .get(`/api/learning-paths/average-times`, {
        params: {
          learning_path_id: id,
        },
      })
      .then((res) => res.data);
  }

  async getLearningPathsQuarterlyAverageScores(id?: string | number) {
    return await axiosClient
      .get(`/api/learning-paths/quarterly-average-scores`, {
        params: {
          learning_path_id: id,
        },
      })
      .then((res) => res.data);
  }

  async getQuarterlyEmployeeLearningPathAverageStats() {
    return await axiosClient
      .get(`/api/learning-paths/quarterly-stats`)
      .then((res) => res.data);
  }

  async getLearningPathsOverallAverageScores(id?: string | number) {
    return await axiosClient
      .get(`/api/learning-paths/overall-average-scores`, {
        params: {
          learning_path_id: id,
        },
      })
      .then((res) => res.data);
  }

  async getLearningPathStatsList(
    { id, page = 1, sortBy, sortDirection, ...filters } = {} as any
  ) {
    return await axiosClient
      .get(`api/learning-paths/stats-list`, {
        params: {
          id,
          page,
          sortBy,
          sortDirection,
          ...filters,
        },
      })
      .then((res) => res.data);
  }

  async getModuleContentSkipChoices() {
    return await axiosClient
      .get(`${ROUTE_API_MODULE_CONTENTS}/${PATH_SKIP_CHOICES}/`)
      .then((res) => res.data);
  }

  async linkAssessmentToModuleContent({
    assessmentId,
    moduleId,
  }: LinkAssessmentToModuleContentRequest): Promise<ModuleContentType> {
    return await axiosClient
      .post(`${ROUTE_API_MODULE_CONTENTS}/${PATH_LINK_ASSESSMENT}/`, {
        assessment_id: assessmentId,
        module_id: moduleId,
      })
      .then((res) => res.data);
  }

  async partialUpdateLearningPath(data: any) {
    return await axiosClient
      .patch(`/api/learning-paths/${data.id}/`, data)
      .then((res) => res.data);
  }

  async reissueLearningPath({
    id,
    employeeId,
  }: {
    id: string;
    employeeId: string;
  }) {
    return await axiosClient
      .post(`/api/learning-paths/${id}/reissue/`, {
        employee_id: employeeId,
      })
      .then((res) => res.data);
  }

  async updateModuleContentFile(
    id: string | number,
    data: Partial<UploadModuleContentFileFormSchemaType>
  ) {
    return await axiosClient
      .patch(`/api/module-contents/update-file/${id}/`, data)
      .then((res) => res.data);
  }

  async updateModuleContentPosition(
    id: string | number,
    moduleContentId: string | number,
    newPosition: string | number
  ): Promise<ModuleContentType[]> {
    return await axiosClient
      .patch(`/api/modules/${id}/update-module-content-position/`, {
        module_content_id: moduleContentId,
        new_position: newPosition,
      })
      .then((res) => res.data);
  }

  async updateModuleTitle({ id, title }: UpdateModuleTitle) {
    return await axiosClient
      .patch(`/api/modules/${id}/`, { title: title })
      .then((res) => res.data);
  }
}

const learningPathService = new LearningPathService();

export default learningPathService;
