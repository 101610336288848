import { Outlet } from "react-router-dom";
import PageContainer from "./components/Layout/PageContainer";
import "react-loading-skeleton/dist/skeleton.css";

export default function App() {
  return (
    <PageContainer style={{ flexDirection: "unset", alignItems: "unset" }}>
      <Outlet />
    </PageContainer>
  );
}
