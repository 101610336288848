import styles from "./LearningPathTakeCompleted.module.css";

export default function LearningPathTakeCompleted() {
  return (
    <div className={styles.completedContainer}>
      <h1 className="cp-yellow">Learning Path Completed!</h1>
      <h4 className="cp-yellow">
        Congratulations! You have completed this learning path.
      </h4>
    </div>
  );
}
