import React from "react";

interface MainContentProps {
  children: React.ReactNode;
}

export default function MainContent({
  children,
}: MainContentProps): JSX.Element {
  return (
    <div className={"mainContent"}>
      {children}
    </div>
  );
}
