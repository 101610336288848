import { useAlert } from "@blaumaus/react-alert";
import { AxiosError } from "axios";
import * as Sentry from "@sentry/react";
import { formatDrfErrors } from "../utils/formatDrfErrors";

export default function useErrorHandling() {
  const alert = useAlert();

  return (error: AxiosError) => {
    Sentry.captureException(error);
    const data = error.response?.data;
    if (data.detail) {
      alert.error(data.detail);
    } else if (data) {
      if (typeof data === "string") {
        alert.error(data);
        return;
      }
      const errorMessages = formatDrfErrors(data?.message || data);
      errorMessages.forEach((errorMessage) => alert.error(errorMessage));
    } else {
      alert.error("An error occurred. Please try again.");
    }
  };
}
