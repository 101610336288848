export enum ButtonColors {
  GrayAndYellow = "gray-and-yellow",
  Gray = "gray",
  Yellow = "yellow",
  Green = "green",
  Red = "red",
  Transparent = "transparent",
  WhiteOutline = "white-outline",
}

export enum ButtonSizes {
  XS = "xs",
  SM = "sm",
  MD = "md",
  LG = "lg",
}
