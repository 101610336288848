import React, { useState, useEffect, useRef, memo } from "react";
import { useSearchParams } from "react-router-dom";

type TabNavigationProps = {
  children: React.ReactElement[];
  onTabChange?: (tabName: string) => void;
  defaultActiveTab?: number;
  id: string;
};

function TabNavigation({
  children,
  onTabChange,
  defaultActiveTab = 0,
  id,
}: TabNavigationProps) {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const tabsRef = useRef<(HTMLDivElement | null)[]>([]);
  const indicatorRef = useRef<HTMLDivElement | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const tabParam = searchParams.get(id + "Tab");
    if (tabParam) {
      const childrenArray = React.Children.toArray(children).filter(
        (child): child is React.ReactElement => React.isValidElement(child)
      );
      const tabIndex = childrenArray.findIndex(
        (child) => child.props.title === tabParam
      );
      if (tabIndex !== -1) {
        setActiveTab(tabIndex);
      }
    }
  }, [searchParams, children, id]);

  useEffect(() => {
    if (activeTab >= 0 && activeTab < children.length) {
      const currentTab = tabsRef.current[activeTab];
      const indicator = indicatorRef.current;
      if (currentTab && indicator) {
        indicator.style.left = `${currentTab.offsetLeft}px`;
      }
    }
  }, [activeTab, children.length]);

  const handleTabClick = (index: number) => {
    const newSearchParams = new URLSearchParams(searchParams);
    const visibleChildren = React.Children.toArray(children).filter(
      (child): child is React.ReactElement =>
        React.isValidElement(child) && child.props.isVisible !== false
    );
    newSearchParams.set(id + "Tab", visibleChildren[index].props.title);
    setSearchParams(newSearchParams);
    setActiveTab(index);
    onTabChange?.(visibleChildren[index].props.title);
  };

  return (
    <div>
      <div className={"tabContainer"}>
        {React.Children.map(children, (child: React.ReactElement, index) =>
          child.props.isVisible !== false
            ? React.cloneElement(child, {
                ref: (el: HTMLDivElement | null) =>
                  (tabsRef.current[index] = el),
                isActive: activeTab === index,
                onClick: () => handleTabClick(index),
              })
            : null
        )}
        <div className={"indicator"} ref={indicatorRef} />
      </div>
      <div className={"tabContent"}>
        {activeTab >= 0 &&
          activeTab < children.length &&
          children[activeTab].props.isVisible !== false &&
          children[activeTab].props.children}
      </div>
    </div>
  );
}

export default memo(TabNavigation);
