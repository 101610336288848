import { useEffect, useRef, useState } from "react";
import Moveable, { OnDrag, OnResize } from "react-moveable";
import { DocumentApprovalAnnotationType } from "./DocumentApprovalForm";
import { AnnotationType } from "../../types/template.types";
import useAuth from "src/features/auth/hooks/useAuth";
import getFullName from "src/features/employees/utils/getFullName";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";

type Position = {
  x: number;
  y: number;
  width: number;
  height: number;
};

type DocumentApprovalAnnotationProps = {
  approvalAnnotation: DocumentApprovalAnnotationType;
  setApprovalAnnotations: (
    updater: (
      annotations: DocumentApprovalAnnotationType[]
    ) => DocumentApprovalAnnotationType[]
  ) => void;
  onApprovalAnnotationClick: (uuid: string) => void;
};

export default function DocumentApprovalAnnotation({
  approvalAnnotation: { uuid, x, y, width, height, signature, type },
  setApprovalAnnotations,
  onApprovalAnnotationClick,
}: DocumentApprovalAnnotationProps) {
  const { employee } = useAuth();
  const annotationRef = useRef<HTMLDivElement | null>(null);
  const lastPositionRef = useRef<Position>({ x, y, width, height });
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (annotationRef.current) {
      annotationRef.current.style.transform = `translate(${x}px, ${y}px)`;
      annotationRef.current.style.width = `${width}px`;
      annotationRef.current.style.height = `${height}px`;
    }
  }, [x, y, width, height]);

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const handleTouchStart = (e: TouchEvent) => {
      onApprovalAnnotationClick(uuid);
    };

    const element = buttonRef.current;
    element?.addEventListener("touchstart", handleTouchStart);

    return () => {
      element?.removeEventListener("touchstart", handleTouchStart);
    };
  }, [uuid, onApprovalAnnotationClick]);

  const getParentBounds = (): DOMRect => {
    return annotationRef.current?.parentNode instanceof HTMLElement
      ? annotationRef.current.parentNode.getBoundingClientRect()
      : new DOMRect();
  };

  const constrainPosition = (
    newX: number,
    newY: number,
    elementWidth: number,
    elementHeight: number
  ) => {
    const bounds = getParentBounds();
    const constrainedX = Math.min(
      Math.max(newX, 0),
      bounds.width - elementWidth
    );
    const constrainedY = Math.min(
      Math.max(newY, 0),
      bounds.height - elementHeight
    );
    return { x: constrainedX, y: constrainedY };
  };

  const handleDrag = (e: OnDrag) => {
    let { x, y } = lastPositionRef.current;

    let newX = x + e.delta[0];
    let newY = y + e.delta[1];

    const constrainedPos = constrainPosition(newX, newY, width, height);

    setApprovalAnnotations((annotations: DocumentApprovalAnnotationType[]) =>
      annotations.map((annotation) =>
        annotation.uuid === uuid
          ? { ...annotation, x: constrainedPos.x, y: constrainedPos.y }
          : annotation
      )
    );

    e.target.style.transform = `translate(${newX}px, ${newY}px)`;

    lastPositionRef.current = {
      x: newX,
      y: newY,
      width: lastPositionRef.current.width,
      height: lastPositionRef.current.height,
    };
  };

  const handleResize = (e: OnResize) => {
    const minWidth = 250;
    const minHeight = 100;
    const maxWidth = 300;
    const maxHeight = 200;
    const newWidth = Math.max(
      minWidth || 0,
      Math.min(e.width, maxWidth || Infinity)
    );
    const newHeight = Math.max(
      minHeight || 0,
      Math.min(e.height, maxHeight || Infinity)
    );
    const newX = e.drag.beforeTranslate[0];
    const newY = e.drag.beforeTranslate[1];

    setApprovalAnnotations((annotations: DocumentApprovalAnnotationType[]) =>
      annotations.map((annotation) =>
        annotation.uuid === uuid
          ? {
              ...annotation,
              x: newX,
              y: newY,
              width: newWidth,
              height: newHeight,
            }
          : annotation
      )
    );
  };

  return (
    <div
      ref={annotationRef}
      className="document-approval-annotation"
      onBlur={() => onApprovalAnnotationClick(uuid)}
      style={{ touchAction: "manipulation" }}
    >
      <div
        style={{
          position: "relative",
          border:
            type === AnnotationType.Approval
              ? "14px solid green"
              : "14px solid red",
        }}
      >
        <span
          style={{
            color: type === AnnotationType.Approval ? "green" : "red",
          }}
          className="document-approval-annotation-text"
        >
          {type === AnnotationType.Approval ? "Approved" : "Denied"}
        </span>
        {signature && (
          <img src={signature} alt="User Signature" className="w-100 h-auto" />
        )}
        <div className="flex" style={{ padding: "0 5px 5px 5px" }}>
          {employee && (
            <span style={{ color: "black", fontSize: "10px" }}>
              By: {getFullName(employee)}
            </span>
          )}
          {signature && (
            <span style={{ color: "black", fontSize: "10px" }}>
              Date:{" "}
              {new Date().toLocaleString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })}
            </span>
          )}
        </div>
        <div style={{ position: "absolute", bottom: -50, right: -15 }}>
          <Button
            buttonRef={buttonRef}
            color={ButtonColors.Gray}
            id="approval-button"
            style={{ width: "130px", borderRadius: "5px" }}
            onClick={() => onApprovalAnnotationClick(uuid)}
            title="Save"
          />
        </div>
      </div>
      {signature && isMounted && (
        <Moveable
          target={annotationRef.current}
          draggable={true}
          resizable={false}
          onDrag={handleDrag}
          onResize={handleResize}
        />
      )}
    </div>
  );
}
