import axiosClient from "src/features/axios/client";
import {
  EmployeeLearningPathDetails,
  EmployeeLearningPathRank,
} from "../learningPaths.types";

class EmployeeLearningPathsService {
  async deleteEmployeeLP(id: number) {
    return await axiosClient.delete(`/api/employee-learning-paths/${id}`);
  }

  async getEmployeeLearningPath(
    id: number | string
  ): Promise<EmployeeLearningPathDetails> {
    return await axiosClient
      .get(`/api/employee-learning-paths/${id}`)
      .then((res) => res.data);
  }

  async getEmployeeLearningPathRank(
    id: string | number
  ): Promise<EmployeeLearningPathRank> {
    return await axiosClient
      .get(`/api/employee-learning-path-rank/${id}/`)
      .then((res) => res.data);
  }

  async getEmployeeOverallProgress(id: number) {
    return await axiosClient
      .get(`/api/employee-learning-paths/employee-stats/${id}/`)
      .then((res) => res.data);
  }
}

const employeeLearningPathsService = new EmployeeLearningPathsService();

export default employeeLearningPathsService;
