import axiosClient from "src/features/axios/client";
import { DepartmentDetail } from "../types/departments.type";
import { SingleDepartmentFormType } from "../components/DepartmentsForm";

type GetDepartmentsParams = {
  search?: string;
};

class DepartmentsService {
  async createDepartment(
    data: SingleDepartmentFormType
  ): Promise<DepartmentDetail> {
    return await axiosClient
      .post("/api/departments/", data)
      .then((res) => res.data);
  }

  async deleteDepartment(id: number): Promise<void> {
    return await axiosClient
      .delete(`/api/departments/${id}/`)
      .then((res) => res.data);
  }

  async getDepartments({
    search,
  }: GetDepartmentsParams): Promise<DepartmentDetail[]> {
    return await axiosClient
      .get("/api/departments", {
        params: {
          search,
        },
      })
      .then((res) => res.data);
  }

  async updateDepartment(
    id: number,
    data: SingleDepartmentFormType
  ): Promise<DepartmentDetail> {
    return await axiosClient
      .put(`/api/departments/${id}/`, data)
      .then((res) => res.data);
  }
}

const departmentsService = new DepartmentsService();

export default departmentsService;
