import { useQuery } from "@tanstack/react-query";

import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import departmentsService from "../services/departmentsService";
import useSearch from "src/features/search/hooks/useSearch";
import { useDebounce } from "src/hooks/useDebounce";

export default function useGetDepartmentsListQuery() {
  const { searchTerm: rawSearchTerm } = useSearch();
  const searchTerm = useDebounce(rawSearchTerm, 700);

  return useQuery({
    queryKey: [QueryKeys.DepartmentsList],
    queryFn: () => departmentsService.getDepartments({ search: searchTerm }),
    refetchOnWindowFocus: false,
  });
}
