import StepsProgressBarItem from "./StepsProgressBarItem";
import styles from "./Steps.module.css";

type StepsProgressBarProps = {
  current: number;
};

export default function RegistrationStepsProgressBar({
  current,
}: StepsProgressBarProps) {
  const totalSteps = 4;
  const progressWidth = ((current - 1) / (totalSteps - 1)) * 100 + "%";

  return (
    <div className={styles.stepBarWrapper}>
      <div className={styles.stepBarContainer}>
        <StepsProgressBarItem step={1} current={current} />
        <StepsProgressBarItem step={2} current={current} />
        <StepsProgressBarItem step={3} current={current} />
        <StepsProgressBarItem step={4} current={current} />
        <div className={styles.stepBar} />
        <div
          className={styles.stepBarProgress}
          style={{ width: `calc(${progressWidth} - 30px)` }}
        />
      </div>
    </div>
  );
}
