import axiosClient from "../axios/client";

const createAssessment = async (data: any) => {
  const { data: responseData } = await axiosClient.post(
    "/api/lp-assessments/",
    data
  );
  return responseData;
};

const createQuestion = async (data: any) => {
  const { data: responseData } = await axiosClient.post(
    `/api/questions/`,
    data
  );
  return responseData;
};

const getAnswerOrderChoices = async () => {
  const { data } = await axiosClient.get(
    "/api/lp-assessments/answer-order-choices/"
  );
  return data;
};

const getSittingQuestion = async ({
  id,
  emcId,
}: {
  id: string;
  emcId: string;
}) => {
  const params = {
    emc_id: emcId,
  };
  const { data } = await axiosClient.get(`/api/lp-assessments/${id}/take/`, {
    params,
  });
  return data;
};

const postSittingAnswers = async ({
  id,
  answers,
  emcId,
}: {
  id: number | string;
  answers: any;
  emcId?: string;
}) => {
  const params = {
    emc_id: emcId,
  };

  const { data } = await axiosClient.post(
    `/api/lp-assessments/${id}/take/`,
    { answers },
    { params }
  );
  return data;
};

const removeQuestionFromAssessment = async (data: any) => {
  const { data: responseData } = await axiosClient.patch(
    `/api/lp-assessments/${data.assessmentId}/remove-question/`,
    {
      question_id: data.questionId,
    }
  );
  return responseData;
};

const updateAssessment = async (data: any) => {
  const { data: responseData } = await axiosClient.patch(
    `/api/lp-assessments/${data.id}/`,
    data
  );
  return responseData;
};

export {
  createAssessment,
  createQuestion,
  getAnswerOrderChoices,
  getSittingQuestion,
  postSittingAnswers,
  removeQuestionFromAssessment,
  updateAssessment,
};
