import React from "react";
import styles from "./ProgressBar.module.css";

interface ProgressBarProps {
  progress: number;
  containerClassName?: string;
  progressClassName?: string;
  progressBarStyle?: React.CSSProperties;
}

function ProgressBar({
  progress,
  containerClassName,
  progressClassName,
  progressBarStyle,
}: ProgressBarProps) {
  const progressWidth = `${progress}%`;

  return (
    <div
      className={`${
        containerClassName ? containerClassName : styles.progressBar
      }`}
      style={progressBarStyle}
    >
      <div
        className={`${styles.progress} ${progressClassName}`}
        style={{ width: progressWidth }}
      ></div>
    </div>
  );
}

export default ProgressBar;
