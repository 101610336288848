export enum UrlRoutes {
  Dashboard = "/dashboard",
  Departments = "/departments",
  Employees = "/employees",
  Login = "/login",
  Logout = "/logout",
  Organization = "/organization",
}

export enum UrlPaths {
  Dashboard = "dashboard",
  Departments = "departments",
  Employees = "employees",
  Login = "login",
  Logout = "logout",
  Organization = "organization",
}
