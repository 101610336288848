import { memo } from "react";
import Button from "../Buttons/Button";
import DescriptionText from "../Text/DescriptionText";
import Container from "../Container/Container";
import globalStyles from "../../globalStyles.module.css";
import { TRY_AGAIN } from "src/features/buttons/titles";
import { ButtonColors } from "../Buttons/buttons.types";

const DEFAULT_ERROR_MESSAGE =
  "There was an issue loading. This error has been reported to the engineering team.";
const CONTAINER_DATA_TEST_ID = "error-fallback";
const BUTTON_DATA_TEST_ID = "reload-button";
const CONTAINER_STYLE = { minHeight: "200px" };

type ErrorFallbackProps = {
  error?: string | Error;
  onReload?: () => void;
};

function ErrorFallback({ error, onReload }: ErrorFallbackProps) {
  const errorMessage = error instanceof Error ? error.message : error;

  return (
    <Container
      className={globalStyles.centered}
      dataTestId={CONTAINER_DATA_TEST_ID}
      style={CONTAINER_STYLE}
    >
      <DescriptionText>{errorMessage || DEFAULT_ERROR_MESSAGE}</DescriptionText>
      {onReload && (
        <Button
          title={TRY_AGAIN}
          color={ButtonColors.Gray}
          onClick={onReload}
          dataTestid={BUTTON_DATA_TEST_ID}
        />
      )}
    </Container>
  );
}

export default memo(ErrorFallback);
