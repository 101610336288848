export const ADD_DROPDOWN_LIST = "Add Dropdown List";
export const ADD_CHECKBOX_OPTIONS = "Add Checkbox Options";
export const CREATE_A_CATEGORY = "Create a Category";
export const CREATE_A_DISCUSSION = "Create a Discussion";
export const CREATE_A_MEMO = "Create a Memo";
export const CREATE_A_POLICY = "Create a Policy";
export const CREATE_A_POLICY_CATEGORY = "Create a Policy Category";
export const CREATE_A_VIDEO = "Create Video";
export const CREATE_DOCUMENT = "Create Document";
export const CREATE_PDF_TEMPLATE = "Create PDF Template";
export const DELETE_ASSESSMENT = "Delete Assessment";
export const DELETE_COLUMN = "Delete This Column?";
export const DELETE_DEPARTMENT = "Delete Department";
export const DELETE_DOCUMENT = "Delete Document";
export const DELETE_EMPLOYEE_LEARNING_PATH = "Delete Employee Learning Path";
export const DELETE_INVITE = "Delete Invite";
export const DELETE_LEARNING_PATH = "Delete Learning Path";
export const DELETE_MEMO = "Delete Memo";
export const DELETE_NOTE = "Delete Note?";
export const DELETE_POLICY = "Delete Policy";
export const DELETE_SCHEDULE = "Delete Schedule";
export const DELETE_TEMPLATE = "Delete Template";
export const DELETE_TODO = "Delete Todo";
export const DELETE_VIDEO = "Delete Video";
export const EMPLOYEE_REGISTRATION = "Employee Registration";
export const EDIT_POLICY = "Edit Policy";
export const EDIT_VIDEO = "Edit Video";
export const IMPORT_EXISTING_QUESTION = "Import Existing Question";
export const INVITE_EMPLOYEE = "Invite an Employee";
export const INVITE_SUPERVISOR = "Invite a Supervisor";
export const LEARNING_PATH = "Learning Path";
export const LEARNING_PATH_DETAILS = "Learning Path Details";
export const LINK_A_POLICY = "Link a Policy";
export const LINK_A_VIDEO = "Link a Video";
export const LINK_AN_ASSESSMENT = "Link an Assessment";
export const LOGIN = "Login";
export const PASSWORD_RESET_REQUEST = "Request Password Reset";
export const PASSWORD_RESET = "Reset Password";
export const POLICY_READ_STATUS = "Policy Read Status";
export const REISSUE_ASSESSMENT = "Reissue Assessment";
export const REISSUE_LP_TO_EMPLOYEE = "Reissue Learning Path to Employee";
export const REQUEST_USERNAME = "Request Username";
export const SCHEDULE_FORM = "Schedule Form";
export const SELECT_EMPLOYEE = "Select Employee for Annotation";
export const SEND_DOCUMENT = "Send Document";
export const SEND_REMINDER_NOTIFICATION = "Send Reminder Notification";
export const SEND_REMINDER_NOTIFICATIONS = "Send Reminder Notifications";
export const UPDATE_EMPLOYEE_DETAILS = "Update Employee Details";
export const UPDATE_MEMO = "Update Memo";
export const UPLOAD_FILE_TO_DISCUSSION = "Upload File to Discussion";
export const VERIFY_PHONE_NUMBER = "Verify Your Phone Number";
export const VIDEO_PREVIEW = "Video Preview";
