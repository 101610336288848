import { useEffect, useMemo, useState } from "react";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import ButtonsContainer from "src/components/Container/ButtonsContainer";
import ResizingPDFViewer from "src/features/pdf/components/ResizingPDFViewer";

type LPPDFViewerProps = {
  startPage: number;
  endPage: number;
  fileUrl: string;
  pageWidth: number;
  onLastPage: (arg0: boolean) => void;
};

export default function LPPDFViewer({
  startPage,
  endPage,
  fileUrl,
  pageWidth,
  onLastPage,
}: LPPDFViewerProps) {
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (startPage) setPageNumber(startPage);
    onLastPage(true);
  }, [startPage, onLastPage]);

  useEffect(() => {
    if (endPage === pageNumber) onLastPage(false);
  }, [pageNumber, onLastPage, endPage]);

  const previousPage = () => setPageNumber((prev) => prev - 1);
  const nextPage = () => setPageNumber((prev) => prev + 1);

  const prevPageBtnDisabled = useMemo(
    () => pageNumber <= startPage,
    [pageNumber, startPage]
  );

  const nextPageBtnDisabled = useMemo(
    () => pageNumber >= endPage,
    [pageNumber, endPage]
  );

  const onDocumentLoadSuccess = () => {};

  const handleOpenNewTab = () => {
    window.open(fileUrl, "_blank");
  };

  return (
    <>
      <ResizingPDFViewer
        pageNumber={pageNumber}
        onPreviousPage={previousPage}
        onNextPage={nextPage}
        onLoadSuccess={onDocumentLoadSuccess}
        file={fileUrl}
        nextBtnDisabled={nextPageBtnDisabled}
        prevBtnDisabled={prevPageBtnDisabled}
        pageWidth={pageWidth}
      />
      <ButtonsContainer>
        <Button color={ButtonColors.Yellow} onClick={handleOpenNewTab}>
          View in New Tab
        </Button>
      </ButtonsContainer>
    </>
  );
}
