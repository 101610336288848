import React from "react";

type ListItemTextProps = {
  title: React.ReactNode;
  subTitle: React.ReactNode;
};

export default function ListItemText({ title, subTitle }: ListItemTextProps) {
  return (
    <div className={"listItemTextContainer"}>
      <div className={"listItemTitle"}>{title}</div>
      <div className={"listItemSubTitle"}>{subTitle}</div>
    </div>
  );
}
