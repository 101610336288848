import React from "react";
import styles from "./TextArea.module.css";
import InputError from "../Errors/InputError";

interface TextAreaProps {
  autoComplete?: string;
  autoFocus?: boolean;
  containerClassName?: string;
  errorClassName?: string;
  inputClassName?: string;
  defaultValue?: string;
  id?: string;
  label?: string | null;
  labelIcon?: React.ReactNode;
  maxLength?: number;
  name: string;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  required?: boolean;
  value?: string;
  rows?: number;
  cols?: number;
  error?: string;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      autoComplete,
      autoFocus,
      containerClassName,
      errorClassName,
      inputClassName,
      id,
      label,
      labelIcon,
      maxLength,
      name,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      placeholder,
      required = false,
      value,
      rows,
      cols,
      error,
      ...rest
    },
    ref
  ) => {
    return (
      <div className={styles.textAreaContainer} id={id}>
        <textarea
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          className={error ? errorClassName : inputClassName}
          id={id}
          maxLength={maxLength}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          required={required}
          value={value}
          ref={ref}
          rows={rows}
          cols={cols}
          aria-describedby={error ? `${id}-error` : undefined}
          {...rest}
        />
        {error ? <InputError error={error} /> : null}
      </div>
    );
  }
);

export default TextArea;
