import React, { useCallback, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import cn from "src/features/utils/cn";
import Button from "../Buttons/Button";
import CloseIcon from "../Icons/CloseIcon";
import { ButtonColors } from "../Buttons/buttons.types";

type DialogProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: React.ReactNode;
};

const Dialog = ({
  isOpen: externalIsOpen,
  setIsOpen,
  children,
}: DialogProps) => {
  const dialogRoot = document.getElementById("dialog-root");
  const [internalIsOpen, setInternalIsOpen] = useState(externalIsOpen);
  const [isAnimatingOut, setIsAnimatingOut] = useState(false);

  useEffect(() => {
    if (externalIsOpen) {
      setInternalIsOpen(true);
    } else if (!externalIsOpen && internalIsOpen && !isAnimatingOut) {
      setIsAnimatingOut(true);
    }
  }, [externalIsOpen, internalIsOpen, isAnimatingOut]);

  const onAnimationEnd = useCallback(
    (e: React.AnimationEvent<HTMLDivElement>) => {
      if (e.animationName === "fadeOutScaleDown") {
        setInternalIsOpen(false);
        setIsAnimatingOut(false);
        setIsOpen(false);
      }
    },
    [setIsOpen]
  );

  const closeDialog = useCallback(() => {
    setIsAnimatingOut(true);
  }, []);

  useEffect(() => {
    if (isAnimatingOut) {
      const timeoutId = setTimeout(() => {
        setInternalIsOpen(false);
        setIsAnimatingOut(false);
        setIsOpen(false);
      }, 100);
      return () => clearTimeout(timeoutId);
    }
  }, [isAnimatingOut, setIsOpen]);

  const renderDialog = useCallback(
    () => (
      <div
        className={cn("dialog", isAnimatingOut ? "dialog-exit" : undefined)}
        onAnimationEnd={onAnimationEnd}
      >
        <div className="dialog-close-button">
          <Button color={ButtonColors.Transparent} onClick={closeDialog}>
            <CloseIcon />
          </Button>
        </div>
        {children}
      </div>
    ),
    [isAnimatingOut, onAnimationEnd, closeDialog, children]
  );

  return internalIsOpen && dialogRoot
    ? ReactDOM.createPortal(renderDialog(), dialogRoot)
    : null;
};

export default Dialog;
